import React, {useState} from 'react';
import {createRoot} from 'react-dom/client';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import {Car} from './cars';
import {RequsetDialog} from './request';

import './img/tahoe1.jpg';
import './img/pilot1.jpg';
import './img/pilot2.jpg';
import './img/montero1.jpg';
import './img/sportage1.jpg';
import './img/prado1.jpg';
import './img/xtrail1.jpg';
import './img/sportage2.jpg';
import './img/outlander1.jpg';
import './img/santafe1.jpg';
import './img/rav41.jpg';

let periods = ['7-13 days', '14-20 days', '21+ days'];

let cars: Car[] = [{
    id: 1,
    name: 'Chevrolet Tahoe',
    // 70
    cost: [80, 75, 70],
    // cost: [65, 60, 50],
    img: '/img/tahoe1.jpg',
}, {
    id: 2,
    name: 'Honda Pilot',
    // 75
    cost: [85, 80, 75],
    // cost: [65, 60, 50],
    img: '/img/pilot1.jpg',
}, {
    id: 3,
    name: 'Mitsubishi Montero',
    // 75
    cost: [85, 80, 75],
    // cost: [65, 60, 50],
    img: '/img/montero1.jpg',
}, {
    id: 4,
    name: 'Kia Sportage',
    // 50
    cost: [60, 55, 50],
    // cost: [50, 45, 40],
    img: '/img/sportage1.jpg',
}, {
    id: 5,
    name: 'Toyota Prado',
    // 150
    cost: [150, 145, 140],
    // cost: [120, 110, 100],
    img: '/img/prado1.jpg',
}, {
    id: 6,
    name: 'Nissan X-Tral',
    // 70
    cost: [80, 75, 70],
    // cost: [65, 60, 50],
    img: '/img/xtrail1.jpg',
}, {
    id: 7,
    name: 'Kia Sportage',
    // 60
    cost: [70, 65, 60],
    // cost: [50, 45, 40],
    img: '/img/sportage2.jpg',
}, {
    id: 8,
    name: 'Mitsubishi Outlander',
    // 60
    cost: [70, 65, 60],
    // cost: [70, 65, 60],
    img: '/img/outlander1.jpg',
}, {
    id: 9,
    name: 'Hyundai Santa Fe',
    // 70
    cost: [80, 75, 70],
    // cost: [60, 55, 50],
    img: '/img/santafe1.jpg',
}, {
    id: 10,
    name: 'Honda Pilot',
    // 75
    cost: [85, 80, 75],
    // cost: [80, 70, 65],
    img: '/img/pilot2.jpg',
}, {
    id: 11,
    name: 'Toyota RAV4',
    // 50
    cost: [60, 55, 50],
    // cost: [50, 45, 40],
    img: '/img/rav41.jpg',
}];

const RcToolbar = ()=>
    <AppBar position="static" sx={{mb: 2}}>
        <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                Rent a сar in Costa Rica
            </Typography>
        </Toolbar>
    </AppBar>;

type CarCardProps = {
    car: Car;
    onRequest: (car: Car)=>void;
};
const CarCard = (props: CarCardProps)=>{
    let {car, onRequest} = props;
    return <Card sx={{mb: 2}}>
        <CardMedia sx={{height: 200}} image={car.img} title={car.name}/>
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {car.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                From <b>${car.cost[2]}/day</b> + $500 deposit
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Insurance is included
            </Typography>
        </CardContent>
        <CardActions>
            <Button variant="contained" onClick={()=>onRequest(car)}>
                Request availability
            </Button>
        </CardActions>
    </Card>;
};

const steps = [
    {
        label: 'Place your order on the website and wait for order confirmation.',
    },
    {
        label: 'We will meet you at the airport, where you can sign the contract, settle the payment, and within 10 minutes the car will be at your disposal.',
    },
    {
        label: 'Enjoy your vacation!',
    },
    {
        label: 'Plan additional 10 minutes at the airport before departure to inspect the car and return the deposit.',
    },
];

function HowItWorks() {
    return (
        <Stepper orientation="vertical">
            {steps.map(step=>(
                <Step key={step.label} active={true}>
                    <StepLabel>
                        {step.label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

const Root = ()=>{
    const [request, setRequest] = useState<Car|undefined>();

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RequsetDialog car={request} handleClose={()=>setRequest(undefined)}/>
        <Container maxWidth="lg">
            <RcToolbar/>
            <Typography variant="h4" sx={{mt: 1, mb: 2}}>How we work:</Typography>
            <HowItWorks/>
            <Typography variant="h4" sx={{mt: 2, mb: 2}}>Our cars:</Typography>
            <Grid container spacing={2}>
                {cars.map(car=><Grid key={car.id} item xs={12} sm={6} md={6} lg={4}>
                    <CarCard car={car} onRequest={setRequest}/>
                </Grid>)}
            </Grid>
                <Box
                    sx={{
                        width: "100%",
                        height: "auto",
                        backgroundColor: "primary.main",
                        paddingTop: "2rem",
                        paddingBottom: "2rem",
                    }}
                >
                    <Typography color="white" sx={{px: 1}}>
                        Transport fleet partners S.A. Santa Ana, San José, Costa Rica<br/>
                        Whatsapp:{' '}
                        <a style={{color: 'white'}} href="https://wa.me/50671138540?text=Hello,%20I%20want%20to%20rent%20an%20SUV.%20Can%20you%20help%20me?">+50671138540</a>
                    </Typography>
                </Box>
        </Container>
    </LocalizationProvider>;
};

createRoot(document.getElementById('root')!).render(<Root/>);
