import React, {useState} from 'react';
import * as dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import {Car} from './cars';

let locations = [
    {value: 'Juan Santamaría International Airport (SJO), Costa Rica'},
    {value: 'Liberia International Airport (LIR), Costa Rica'},
    {value: 'Santa Ana, Costa Rica'},
];

function minPeriod(location: string){
    return location=='Liberia International Airport (LIR), Costa Rica' ? 14 : 7;
}

type RequsetDialogProps = {
    car?: Car,
    handleClose: ()=>void,
};
export function RequsetDialog({car, handleClose}: RequsetDialogProps){
    let [pickupLocation, setPickupLocation] =
        useState<string>(locations[0].value);
    let [pickupDate, setPickupDate] =
        useState<dayjs.Dayjs|null>(dayjs().startOf('day'));
    let [returnDate, setReturnDate] =
        useState<dayjs.Dayjs|null>(dayjs().startOf('day').add(14, 'day'));
    let [name, setName] = useState<string>('');
    let [phone, setPhone] = useState<string>('');
    let [email, setEmail] = useState<string>('');

    let [sending, setSending] = useState<boolean>(false);
    let [result, setResult] = useState<string>('');

    let close = ()=>{
        setResult('');
        handleClose();
    };

    if (!car)
        return null;

    let desc = '';
    if (pickupDate && returnDate)
    {
        let days = +returnDate.diff(pickupDate, 'day');
        let priceIndex = 0;
        if (days>=14)
            priceIndex = 1;
        if (days>=21)
            priceIndex = 2;
        desc = `${days} days * $${car.cost[priceIndex]} = $${days*car.cost[priceIndex]} + $500 deposit.`;
    }

    if (sending)
    {
        return <Dialog open={true}>
            <DialogTitle>Request {car.name}</DialogTitle>
            <DialogContent>
                <Typography>Please wait...</Typography>
            </DialogContent>
        </Dialog>;
    }

    if (result)
    {
        return <Dialog open={true} onClose={close}>
            <DialogTitle>Request {car.name}</DialogTitle>
            <DialogContent>
                <Typography>{result}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Done</Button>
            </DialogActions>
        </Dialog>;
    }

    return <Dialog open={true} onClose={close}
        fullScreen={(navigator as any).userAgentData.mobile} maxWidth="sm"
        PaperProps={{
            component: 'form',
            onSubmit: async (event: React.FormEvent<HTMLFormElement>)=>{
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries((formData as any).entries());
                formJson.car = car.name;
                formJson.desc = desc;
                setSending(true);
                const response = await fetch('/request', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(formJson),
                });
                let res = await response.json();
                if (res.ok)
                    setResult('Thank you for your request. We will contact you during business hours.');
                else
                    setResult('Something went wrong. Please try again later.');
                setSending(false);
            },
        }}
    >
        <DialogTitle>Request {car.name}</DialogTitle>
        <DialogContent>
            <TextField id="pickupLocation" name="pickupLocation"
                margin="normal" label="Pick-up location"
                value={pickupLocation}
                onChange={ev=>{
                    setPickupLocation(ev.target.value);
                    if (dayjs(returnDate).diff(pickupDate, 'day')<minPeriod(ev.target.value))
                        setReturnDate(dayjs(pickupDate).add(minPeriod(ev.target.value), 'day'));
                }}
                required select fullWidth variant="outlined">
                {locations.map(option=>(
                    <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                ))}
            </TextField>
            <DatePicker sx={{mt: 2, mb: 1, width: 1}}
                name="pickupDate"
                label="Pick-up date" closeOnSelect={true}
                slotProps={{textField: {required: true}}}
                minDate={dayjs().startOf('day').add(1, 'day')}
                maxDate={dayjs().startOf('day').add(120, 'day')}
                value={pickupDate} onChange={dt=>{
                    setPickupDate(dt);
                    if (dayjs(returnDate).diff(dt, 'day')<minPeriod(pickupLocation))
                        setReturnDate(dayjs(dt).add(minPeriod(pickupLocation), 'day'));
                }}/>
            <DatePicker sx={{mt: 2, mb: 1, width: 1}}
                name="returnDate"
                label="Return date" closeOnSelect={true}
                slotProps={{textField: {required: true}}}
                minDate={dayjs(pickupDate).startOf('day')
                    .add(minPeriod(pickupLocation), 'day')}
                maxDate={dayjs().startOf('day').add(150, 'day')}
                value={returnDate} onChange={dt=>setReturnDate(dt)}/>
            <TextField id="name" name="name" label="Name"
                type="name"
                required margin="normal" fullWidth variant="outlined"
                value={name} onChange={ev=>setName(ev.target.value)}
            />
            <TextField id="phone" name="phone" label="Phone Number"
                type="phone"
                required margin="normal" fullWidth variant="outlined"
                value={phone} onChange={ev=>setPhone(ev.target.value)}
            />
            <TextField id="name" name="email" label="Email Address"
                type="email"
                required margin="normal" fullWidth variant="outlined"
                value={email} onChange={ev=>setEmail(ev.target.value)}
            />
            <Typography>{desc}</Typography>
            <Typography>The more weeks are chosen, the lower the price per
                day is. To see the minimal price, select a period from
                3 weeks.</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button type="submit">Request</Button>
        </DialogActions>
    </Dialog>;
};
